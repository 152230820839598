$(document).ready(function () {

    /*** Header fixed ***/
    $(window).scroll(function () {
        var scrollPixel = $(window).scrollTop();
        if (scrollPixel < 100) {
          $('header').removeClass('headerFix');
        } else {
          $('header').addClass('headerFix');
        };
    });
    headerResize();
    
});

/*** Mobile Header toggle ***/
function headerResize() {
  var screen_width = $(window).width();
  if (screen_width < 991) {
    $('.navbar-toggler').off('click');
    $('.navbar-toggler').on('click', function () {
      $('header nav').toggleClass('navbar-show');
      $('#divNavbar').slideToggle(200);
    });

    $("#divNavbar > ul > li.dropdown > .dropdown-menu").hide();
    $('#divNavbar > ul > li.dropdown > .arrow').off('click');
    $('#divNavbar > ul > li.dropdown > .arrow').on('click', function (event) {
      event.preventDefault();
      $(this).closest('#divNavbar > ul > li.dropdown').find('> .dropdown-menu').slideToggle('slow');
      $(this).parent().toggleClass('open-dropdown');
    });
  } else {
    $('header nav').removeClass('navbar-show');
    $('.navbar-toggler').attr('aria-expanded', 'false');
    $("#divNavbar > ul > li.dropdown > .dropdown-menu").removeAttr("style");
    $("header .navbar-collapse").removeAttr("style").removeClass('show');
  }

  /****Counter JS****/
$(function () {
  function isScrolledIntoView($elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var elemTop = $elem.offset().top;
    var elemBottom = elemTop + $elem.height();
    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  }
  function count($this) {
    var current = parseInt($this.html(), 10);
    if (isScrolledIntoView($this) && !$this.data("isCounting") && current < $this.data('count')) {
      $this.html(++current);
      $this.data("isCounting", true);
      setTimeout(function () {
        $this.data("isCounting", false);
        count($this);
      }, 50);
    }
  }
  $(".count-num").each(function () {
    $(this).data('count', parseInt($(this).html(), 10));
    $(this).html('0');
    $(this).data("isCounting", false);
  });
  function startCount() {
    $(".count-num").each(function () {
      count($(this));
    });
  };

  $(window).scroll(function () {
    startCount();
  });
  startCount();
});
/**** End Counter JS****/


/* Accordian */
$(".card").on("show.bs.collapse hide.bs.collapse", function(e) {
  if (e.type == 'show') {
    $(this).addClass('active');
  } else {
    $(this).removeClass('active');
  }
});
$('.card').on('shown.bs.collapse', function(e) {
  var $panel = $(this).closest('.card');
  var $headerheight = $('header').outerHeight(true);
  $('html,body').animate({
    scrollTop: $panel.offset().top - $headerheight - 2
  }, 500);
});

/*** Order Pad Open ***/
$('#orderPad').on('shown.bs.collapse', function () {
  $(".order-pad .card-body").niceScroll({
    smoothscroll: true,
    scrollspeed: 60,
    mousescrollstep: 40,
  });
  $("body").addClass('body-filxed');
});
$('#orderPad').on('hide.bs.collapse', function () {
  $("body").removeClass('body-filxed');
})

$('.order-pad-close').on('click', function () {
  $('#orderPad').collapse('hide');
})

/*** Body Nicescroll ***/
var nice = false;
$(function() {  
  nice = $("html").niceScroll({
    smoothscroll: true,
    scrollspeed: 60,
    mousescrollstep: 40,
    cursorcolor:"#c5161b",
    cursorwidth:"5px",     
    cursorborder:"1px solid #c5161b",
    cursorborderradius:0
  });
});

/*** Carousel Slider on mouuse drag ***/
$(".carousel").swipe({
  swipe: function (event, direction, distance, duration, fingerCount, fingerData) {
    if (direction == 'left') $(this).carousel('next');
    if (direction == 'right') $(this).carousel('prev');
  },
  allowPageScroll: "vertical" 
});

/*** WOW animation library initialization ***/
var wow = new WOW(
  {
      animateClass: 'animated',
      offset: 100,
      mobile: false
  }
  );
  wow.init();


  $('.home-slider').slick({
    infinite: true,
    dots: true,
    fade: true,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          arrows: false
        }
      },
      {
        breakpoint: 320,
        settings: {          
          slidesToShow: 1
        }
      }
    ]
  });
}


(function($) {

    $.fn.chosenImage = function(options) {
console.log('Image chsn');
        return this.each(function() {

            var $select = $(this),
                imgMap  = {};

            // 1. Retrieve img-src from data attribute and build object of image sources for each list item
            $select.find('option').filter(function(){
                return $(this).text();
            }).each(function(i) {
                    var imgSrc   = $(this).attr('data-img-src');
                    imgMap[i]    = imgSrc;
            });


            // 2. Execute chosen plugin
            $select.chosen(options);

            // 2.1 update (or create) div.chzn-container id
            /*var chzn_id = $select.attr('id').length ? $select.attr('id').replace(/[^\w]/g, '_') : this.generate_field_id();*/
            var chzn_id = $select.attr('id');
            chzn_id += "_chzn";
            console.log('before class addition');
            var  chzn      = '#' + chzn_id,            
                $chzn      = $(chzn).addClass('chznImage-container');
            console.log(chzn);
            console.log($(chzn).length);
            console.log($("#my-select").length);


            // 3. Style lis with image sources
            $chzn.find('.chzn-results li').each(function(i) {
                $(this).css(cssObj(imgMap[i]));
            });


            // 4. Change image on chosen selected element when form changes
            $select.change(function() {
                var imgSrc = ($select.find('option:selected').attr('data-img-src')) 
                ? $select.find('option:selected').attr('data-img-src') : '';
                $chzn.find('.chzn-single span').css(cssObj(imgSrc));
            });

            $select.trigger('change');


            // Utilties
            function cssObj(imgSrc) {
                if(imgSrc) {
                    return {
                        'background-image': 'url(' + imgSrc + ')',
                        'background-repeat': 'no-repeat'
                    }
                } else {
                    return {
                        'background-image': 'none'
                    }
                }
            }
        });
    }

})(jQuery);

$(document).ready(function() {
    $("#my-select").chosenImage();
    
    // some code that adding/removing options in select
    
    $('#my-select').trigger('liszt:updated'); 
    $("#my-select").chosenImage();
});
